import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const ResponsiveContext = createContext();

export const ResponsiveProvider = ({ children }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1200 });

  return (
    <ResponsiveContext.Provider value={{ isSmallScreen }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export const useResponsive = () => {
  const context = useContext(ResponsiveContext);
  if (!context) {
    throw new Error('useResponsive must be used within a ResponsiveProvider');
  }
  return context;
};
