import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/LayoutIndex';
import HomeIndex from './components/Home/HomeIndex';
import About from './components/About/AboutIndex';
import Contact from './components/Contact/Contact';

function App() {
  return ( 
    <>
    <Routes>
      <Route path="/" element={<Layout />} >
      <Route index element={<HomeIndex /> } />
      <Route path = '/about' element={<About /> } />
      <Route path='/contact' element={<Contact /> } />
      </Route>
    </Routes>
    </>
  );
}

export default App;
