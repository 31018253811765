import React, { useEffect, useRef, useState } from 'react'
import './ContactIndex.scss';
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters/AnimatedLettersIndex'
import emailjs from '@emailjs/browser';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
 


const Contact = () => {
    const strArray = 'Contact Me'.split('')
    const [letterClass, setLetterClass] = useState('text-animate');

    const refForm = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(
            process.env.REACT_APP_EMAIL_SERVICE_ID,
            process.env.REACT_APP_EMAIL_TEMPLAT_ID,
            refForm.current,
            process.env.REACT_APP_EMAIL_PRIVATE_KEY
        ).then(() => {
            alert('Message successfully sent!')
            window.location.reload(false);
        }, () => {
            alert('Failed to send message, please try again!')
        })
    }


    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 4000);
    
        return () => clearTimeout(timeoutId)
    
      }, []);  


  return (
    <>
    <div className='container contact-page'>
        <div className='text-zone contact'>
            <h1>
                <AnimatedLetters
                    strArray={strArray}
                    index={15}
                    letterClass={letterClass}
                />
            </h1>

            <p>
                If you have any questions please
                don't hesitate to contact me using the form below.
            </p>

                <form ref={refForm} onSubmit={sendEmail} className='contact-form'>
                    <ul>
                        <li className='half'>
                            <input type='text' name='name' placeholder='Name' required />
                        </li>
                        <li className='half'>
                            <input type='email' name='email' placeholder='Email' required />
                        </li>

                        <li>
                            <input placeholder="Subject" type="text" name="subject" required/>
                        </li>

                        <li>
                            <textarea placeholder='Message' name='message' required></textarea>
                        </li>

                        <li>
                            <input type='submit' className='flat-button' value='SEND' />
                        </li>
                    </ul>
                </form>
            </div>


        <div className='info-map'>
            Cormac Coulter
            <br />
            Belfast, Ireland <br />
            <span>cormaccoulter@hotmail.com</span>

        </div>

        <div className='map-wrap'>
            <MapContainer center = {[54.59682, -5.92541]} zoom={13}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={[54.59682, -5.92541]}>
                    <Popup>Cormac Lives here</Popup>
                </Marker>

            </MapContainer>
        </div>
         
    </div>
    <Loader type='pacman'/>
    </>

  )
}

export default Contact