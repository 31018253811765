import { NavLink } from 'react-router-dom';
import './SidebarIndex.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faHome, faUser} from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';
import { slide as Menu } from 'react-burger-menu'



const Sidebar = () => {

    const [state, setState] = useState(false);

    const handleClose = () => {
        setState(false);
    };

    return (
        <Menu
            className='menu'
            width={'150px'}
            isOpen={state}
            onStateChange={(menuState => setState(menuState.isOpen))}
        >

        {/* <nav> */}
            <NavLink
            className='home-link'
                exact='true'
                activeclassname='active'
                to='/ '
                onClick={handleClose}
            >
                <FontAwesomeIcon icon={faHome} color='#ababab' />
            </NavLink>
            <NavLink exact='true' activeclassname='active' className='about-link' to='/about' onClick={handleClose}>
                <FontAwesomeIcon icon={faUser} color='#ababab' />
            </NavLink>
            <NavLink exact='true' activeclassname='active' className='contact-link' to='/contact' onClick={handleClose}>
                <FontAwesomeIcon icon={faEnvelope} color='#ababab' />
            </NavLink>
        {/* </nav> */}

        
        <ul className='ul-icon'>
            <li>
                <a target='_blank' className='icon-link' rel='noreferrer' href='https://www.linkedin.com/in/cormac-coulter/'>
                    <FontAwesomeIcon icon={faLinkedin} color='#ababab' />
                </a>
            </li>
            <li>
                <a target='_blank' rel='noreferrer' href='https://github.com/c-mac1'>
                    <FontAwesomeIcon icon={faGithub} color='#ababab' />
                </a>
            </li>
        </ul>
        


    </Menu>
    )
}


export default Sidebar;