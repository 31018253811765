import React, { useEffect, useState } from 'react'
import './AboutIndex.scss';
import AnimatedLetters from '../AnimatedLetters/AnimatedLettersIndex'
import Loader from 'react-loaders'
import TimelineIndex from './Timeline/Timeline';

const About = () => {

const [letterClass, setLetterClass] = useState('text-animate')
const strArray = 'About me'.split('');

useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000);

    return () => clearTimeout(timeoutId)

  }, []);  
  return (
    <>
    <div className='container about-page'>
        <div className='text-zone about'>
            <h1>
                <AnimatedLetters
                    letterClass={letterClass}
                    strArray={strArray}
                    index={15}
                />
            </h1>
            <p>
                I'm a very ambitious Software Engineer currently working full stack in Angular and AWS. 
            </p>

            <p>
                I'm naturally curious and always wanting to learn new technologies and so have experience in other languages such as React and Python.
            </p>

            <p>
                I have many other hobbies such as Sports, Music and travelling
            </p>
        </div>

        <div className='timeline'>
            <TimelineIndex />
        </div>
    </div>
    <Loader type='pacman'/>
    </>
  )
}

export default About