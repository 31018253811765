import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './HomeIndex.scss'
import AnimatedLetters from '../AnimatedLetters/AnimatedLettersIndex'
import Cube from './Cube/CubeIndex'
import Loader from 'react-loaders'
import { useResponsive } from '../Responsiveness/ResponsiveContext';



const HomeIndex = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const nameArray = 'ormac.'.split('');
  const jobArray = 'Software Engineer.'.split('');

  const {isSmallScreen} = useResponsive()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000);

    return () => clearTimeout(timeoutId)

  }, []);  

  return (
    <>
    <div className={`container home-page ${isSmallScreen ? 'small-screen' : 'normal'}`}>
    <div className='text-container'>
      <div className='text-zone'>
        <h1 className='h1-small'>
          <span className={letterClass}>H</span>
          <span className={`${letterClass} _12`}>i,</span>
          <br />
          <span className={`${letterClass} _13`}>I</span>
          <span className={`${letterClass} _14`}>'m</span>
           <span className={`${letterClass} _15 image`}> &nbsp;C</span>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={nameArray}
            index={6}
          />
          <br />

          <AnimatedLetters
            letterClass={letterClass}
            strArray={jobArray}
            index={19}
          />
        </h1>
        <Link to='/contact' className='flat-button'>Contact Me</Link>
      </div>
      </div>

      <Cube />

     
    </div>
    <Loader type='pacman' />
    </>
  )
}

export default HomeIndex