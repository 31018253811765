import Sidebar from '../Sidebar/SidebarIndex';
import { Outlet } from 'react-router-dom'
import './LayoutIndex.scss';

const Layout = () => {
    return (
        <div className='app'>
            <Sidebar />
            <div className='page'>
                <Outlet />
            </div>
        </div>
    )
}

export default Layout;