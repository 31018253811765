import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';


const TimelineIndex = () => {
    return (
        <Timeline >
        <TimelineItem>
          <TimelineOppositeContent sx={{
            color:"text.secondary",
            fontSize: '2rem'
            }}>
            Sonrai Analytics
            <Typography  sx={{ maxWidth: 'none !important' }}>2021-2024</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ fontSize: '1.5rem' }}>Senior Software Engineer
          <Typography>
          Developing and maintaining code using Angular, working with the Database using PostgreSQL and GraphQL and experience with AWS cloud services such as Lambdas, EC2 Instances, S3 Buckets, Cognito, API Gateway, RDS, Athena & more.
          </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>


          <TimelineOppositeContent sx={{
            color:"text.secondary",
            fontSize: '2rem'
            }}>
            Exploristics
            <Typography  sx={{ maxWidth: 'none !important' }}>2020-2021</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ fontSize: '1.5rem' }}>Full Stack Engineer
          <Typography>
            Gained exposure in Angular and AWS Services.
          </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>


          <TimelineOppositeContent sx={{
            color:"text.secondary",
            fontSize: '2rem'
            }}>
            Allstate
            <Typography  sx={{ maxWidth: 'none !important' }}>2019-2020</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ fontSize: '1.5rem' }}>Service Engineer
          <Typography>
          Supporting enterprise middleware Platforms Layer7 (CA Technologies) and APIGEE, as well as CI/CD and DevOps processes. Exposure with Linux, Python, AWS, Jenkins, Ansible, GitHub, JIRA, Confluence, Splunk 
          </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>


          <TimelineOppositeContent sx={{
            color:"text.secondary",
            fontSize: '2rem'
            }}
          >
            Queens University Belfast
            <Typography  sx={{ maxWidth: 'none !important' }}>2015-2019</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
            <TimelineContent sx={{ fontSize: '1.5rem' }}>
            BSc Biomedical Science & MSc Software Development
            <Typography>
            Software Development modules: Programming, Databases, Software Engineering, Software Testing and Verification, Web Development and an Individual Software Development Project. 
          </Typography>
        </TimelineContent>

        </TimelineItem>
      </Timeline>
    );
}

export default TimelineIndex