import React from 'react'
import './CubeIndex.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngular, faAws, faGitAlt, faHtml5, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons';

const Cube = () => {

  return (
      <div className='stage-cube-cont'>
        <div className='cubespinner'>
          <div className='face1'>
            <FontAwesomeIcon icon={faAngular} color="#DD0031" />
          </div>
          <div className='face2'>
            <FontAwesomeIcon icon={faHtml5} color="#f06529" />
          </div>
          <div className='face3'>
            <FontAwesomeIcon icon={faAws} color="#28A4D9" />
          </div>
          <div className='face4'>
            <FontAwesomeIcon icon={faReact} color="#5eD4f4" />
          </div>
          <div className='face5'>
            <FontAwesomeIcon icon={faJsSquare} color="#EFd81D" />
          </div>
          <div className='face6'>
            <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
          </div>
      </div>
     </div >
  )
}

export default Cube